import React, { FC, ReactNode, useEffect, useRef } from 'react';
import {
  EnrichedService,
  ServiceListLayoutOptions,
} from '../../../../../types/types';
import { ReferralInfo } from '../../../../../utils/bi/consts';
import { useWidgetActions } from '../../hooks/useWidgetActions';
import { Card } from './Card/Card';
import { Strip } from './Strip/Strip';
import { Overlapping } from './Overlapping/Overlapping';
import { ServiceInfo } from './ServiceInfo/ServiceInfo';
import { ServiceImage } from './ServiceImage/ServiceImage';
import { useWidgetViewModel } from '../../hooks/useWidgetViewModel';
import { useVisibilityCheck } from '../../../hooks/useVisibilityCheck';
import { useAddVisibilityClass } from '../../../hooks/useAddVisibilityClass';
import { classes } from './ServiceCard.st.css';

export type ServiceCardProps = {
  service: EnrichedService;
  width: number;
  height: number;
  index: number;

};

export interface ServiceLayoutProps {
  image: ReactNode;
  info: ReactNode;
  index?: number;
}

function getCardComponent({
  serviceListLayout,
}: {
  serviceListLayout: ServiceListLayoutOptions;
}) {
  switch (serviceListLayout) {
    case ServiceListLayoutOptions.OVERLAPPING:
      return Overlapping;
    case ServiceListLayoutOptions.STRIP:
      return Strip;
    case ServiceListLayoutOptions.GRID:
    case ServiceListLayoutOptions.CLASSIC:
    default:
      return Card;
  }
}

export const ServiceCard: FC<ServiceCardProps> = (props) => {
  const { service, width, height, index } = props;
  const { serviceListLayout } = useWidgetViewModel();

  const CardComponent = getCardComponent({ serviceListLayout });
  const { onServiceClick } = useWidgetActions();
  const { shouldBeVisible } = useVisibilityCheck();
  const { addVisibilityClass } = useAddVisibilityClass();
  const isServiceImageVisible = shouldBeVisible('isServiceImageVisible');

  return (
    <CardComponent
      index={index}
      info={<ServiceInfo service={service} />}
      image={
        isServiceImageVisible ? (
          <ServiceImage
            className={addVisibilityClass(
              '',
              classes.serviceImageVisibility,
              'isServiceImageVisible',
            )}
            image={service.media?.mainMedia?.image}
            onClick={() =>
              onServiceClick({
                service,
                referralInfo: ReferralInfo.SERVICE_IMAGE,
              })
            }
            imageTitle={service.name!}
            width={width}
            height={height}
          />
        ) : null
      }
    />
  );
};
