import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useAddVisibilityClass } from '../../../../../hooks/useAddVisibilityClass';
import stylesParams from '../../../../../stylesParams';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import settingsParams from '../../../../../settingsParams';
import { classes, st } from './TagLine.st.css';
import { DataHooks, MAX_TAGLINE_LENGTH } from './consts';

export type TagLineProps = {
  tagLine: string;
};

export const TagLine: FC<TagLineProps> = ({ tagLine }) => {
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const settings = useSettings();
  const { addVisibilityClass } = useAddVisibilityClass();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  const tagName = shouldWorkWithAppSettings
    ? styles.get(stylesParams.serviceTagLineFont).htmlTag ||
      settings.get(settingsParams.serviceTagLineFontHtmlTag)
    : settings.get(settingsParams.serviceTagLineFontHtmlTag);
  return (
    <div
      tabIndex={-1}
      data-hook={DataHooks.ROOT}
      className={addVisibilityClass(
        st(classes.root, { isMobile }),
        classes.tagLineVisibility,
        'isTagLineVisible',
      )}
    >
      <Text
        data-hook={DataHooks.TEXT}
        className={classes.text}
        tagName={tagName}
      >
        {shortenText(tagLine, MAX_TAGLINE_LENGTH)}
      </Text>
    </div>
  );
};

const shortenText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
